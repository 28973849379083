@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
}

button,
input,
select,
textarea {
    border: none;
    outline: none;
    border: 1px solid #212121;
}

.title {
    font-size: 32px;
}

.primary {
    color: #FF5722;
}

.light {
    color: #fff;
}