.banner_section {
    width: 100%;
    text-align: center;
    padding: 40px;
    background: rebeccapurple;
    border-bottom-left-radius: 50% 30%;
    border-bottom-right-radius: 50% 30%;

    &.product {
        padding-bottom: 10rem;
    }

    .section_heading {
        padding-bottom: 3rem;
        color: #fff;

        p {
            width: 60%;
            margin: auto;
        }

        .form_group {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 16px;
            border: 2px solid #fff;
            background: #ffffff90;
            margin-top: 1rem;
            border-radius: 35px;
            color: #000;
            width: 60%;
            margin-inline: auto;

            input {
                width: 100%;
                padding: 12px 20px;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                color: #000;
                background: transparent;

                &::placeholder {
                    color: #000;
                }
            }

            button {
                padding: 12px 18px;
                font-size: 22px;
                border-radius: 30px;
                cursor: pointer;
                text-transform: capitalize;

                &>* {
                    vertical-align: middle;
                }
            }
        }
    }

    .row_col {
        margin-bottom: -5rem;
        max-width: 900px;
        margin-inline: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        .card {
            position: relative;
            isolation: isolate;
            flex: 1;
            flex-basis: 278px;
            background: #fff;
            border-bottom: 5px solid #ff5722;
            text-align: center;
            padding: 40px;
            border-radius: 10px;
            box-shadow: 2px 4px 6px #00000040;

            img {
                position: absolute;
                left: 25%;
                bottom: 0;
                z-index: -1;
                opacity: 0.2;
                width: 150px;
            }

            .icon {
                font-size: 3rem;
                margin: auto;
                display: block;
                margin-bottom: 8px;
                color: #ff5722;
            }

            h1 {
                font-size: 20px;
                text-transform: capitalize;
            }

            a,
            address {
                font-weight: 600;
                display: block;
                color: #313131;
            }
        }
    }
}

.contact_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding-top: 4rem;

    .contact_iframe {
        width: 60%;

        iframe {
            border: none;
            border-radius: 8px;
            background: #eee;
            border-top: 8px solid #FF5722;
            transition: all 0.5s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .contact_form {
        position: relative;
        background-color: #e0e0e0;
        box-shadow: 0 2px 4px #00000040;
        padding: 20px;
        width: 40%;

        img {
            position: absolute;
            top: -10%;
            right: 10%;
            width: 100px;
        }

        .form-title {
            font-size: 26px;
            color: #FF5722;
            margin-bottom: 2rem;
        }

        .form_group {
            margin-bottom: 1rem;

            label {
                display: block;
                font-size: 14px;
            }

            .input_box {
                width: 100%;
                padding: 12px;
                border-radius: 4px;
                border: 1px solid #eee;
            }

            textarea {
                resize: none;
            }

        }

        .btn-submit {
            margin-right: auto;
            padding: 8px 14px;
            background: #313131;
            color: #fff;
            text-transform: capitalize;
            font-size: 16px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width:900px) {
    .contact_area {
        flex-direction: column;

        .contact_iframe {
            width: 100%;

            iframe {
                width: 100%;

            }
        }

        .contact_form {
            width: 70%;
        }
    }

    .banner_section {
        border-bottom-left-radius: 50% 15%;
        border-bottom-right-radius: 50% 15%;

        .section_heading {
            padding-bottom: 3rem;
            color: #fff;

            p {
                width: 100%;
                margin: auto;
            }
        }
    }
}

@media screen and (max-width:500px) {
    .contact_area {
        .contact_form {
            width: 100%;
        }
    }



    .banner_section {
        border-bottom-left-radius: 50% 5%;
        border-bottom-right-radius: 50% 5%;

        .form_group {
            width: 100% !important;

            input {
                width: 100%;
                padding: 10px;
            }

            button {
                padding: 14px 18px !important;
                font-size: 16px !important;
            }
        }
    }

}

// css for contact card

.contact_card {
    position: relative;
    background-color: #313131;
    padding: 40px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    color: #fff;
    isolation: isolate;
    overflow: hidden;

    .content {
        flex-basis: 60%;

        h2 {
            margin-bottom: 8px;

            &::before {
                content: '';
                width: 100px;
                height: 100px;
                background-color: #eee;
                opacity: 0.1;
                position: absolute;
                top: 20px;
                left: 30px;
                border-radius: 50%;
                z-index: -1;
            }

            &::after {
                content: '';
                width: 600px;
                height: 600px;
                background-color: #eee;
                opacity: 0.1;
                position: absolute;
                bottom: -20%;
                right: -20%;
                border-radius: 50%;
                z-index: -1;
                box-shadow: 0 0 0 200px #000;
            }
        }

        p {
            margin-bottom: 1rem;
        }

        ul {
            display: flex;
            gap: 2rem;

            .icon {
                color: #24fc24;

                &>* {
                    vertical-align: middle;
                }
            }
        }
    }

    .button {
        .contact_btn {
            padding: 8px 14px;
            background: #FF5722;
            color: #fff;
            font-weight: 600;
            text-transform: capitalize;
            font-size: 16px;
            border-radius: 8px;

        }
    }
}

@media screen and (max-width:800px) {
    .contact_card {
        flex-direction: column;
        padding: 30px;
        gap: 2rem;

        ul {
            flex-direction: column;
            gap: 1rem !important;
        }

        .contact_btn {
            padding: 12px 36px !important;
            border-radius: 30px !important;
            box-shadow: 0 0 0 8px !important;
        }
    }
}