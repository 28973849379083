@media (max-width:870px) {
    .navbar {
        padding: 15px 20px !important;

        .toogle_menu {
            display: block !important;
            margin-right: 12px;
            font-size: 22px;
            cursor: pointer;

            &>* {
                vertical-align: middle;
            }
        }

        .logo {
            flex: 1;
        }

        .main_menu {
            position: absolute;
            top: 70px;
            left: -500px;
            flex-direction: column;
            background-color: #fff;
            width: 100%;
            gap: 0rem !important;
            border-bottom: 2px solid #FF5722;
            visibility: hidden;
            opacity: 0;
            transition: all 0.6s ease-in-out;

            &.active {
                opacity: 1;
                left: 0;
                visibility: visible;
            }

            .menu_link {
                display: block;
                padding: 12px 20px;

                &:hover {
                    background: #eee;
                }

            }
        }
    }
}

@media (max-width:500px) {
    .logo_text {
        font-size: 16px !important;

        .logo_icon {
            width: 30px !important;
            height: 30px !important;
            margin-right: 2px;
        }
    }

    .right_menu {
        gap: 8px !important;

        .btn_get_start {
            display: none;
        }

        .nav_user_iocn {
            display: block !important;
        }
    }
}

.header {
    background: #fff;
    box-shadow: 0px 2px 8px #00000025;
    width: 100%;
    position: relative;

    .navbar {
        padding: 20px 40px;
        max-width: 1440px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo_text {
            font-size: 20px;
            font-weight: 700;

            span {
                color: #FF5722;
            }

            .logo_icon {
                margin-right: 6px;
                vertical-align: bottom;
                width: 40px;
            }
        }

        .toogle_menu {
            display: none;
        }

        .main_menu {
            display: flex;
            gap: 3rem;
            z-index: 5;

            .menu_link {
                color: #313131;
                font-size: 16px;
                font-weight: 600;

                &.active {
                    color: #FF5722;
                }
            }
        }

        .right_menu {
            display: flex;
            align-items: center;
            gap: 1rem;

            .nav_user_iocn {
                display: none;
            }

            .btn_get_start {
                padding: 8px 14px;
                background: #FF5722;
                color: #fff;
                font-weight: 600;
                text-transform: capitalize;
                font-size: 16px;
                border-radius: 8px;
            }

            .btn_icon_circle {
                padding: 5px 12px;
                border-radius: 50%;
                color: #313131;
                font-size: 20px;
                transition: all 0.5s linear;

                &:hover {
                    background: #ff572230;
                }
            }
        }
    }
}