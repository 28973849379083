/* General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
  }
  
  /* Banner */
  .banner_section {
    background-color: #ff6f61;
    color: white;
    padding: 40px 0;
    text-align: center;
  }
  
  .banner_section .section_heading .title {
    font-size: 2.5rem;
    letter-spacing: 2px;
    font-weight: bold;
  }
  
  .primary {
    color: #fff;
  }
  
  /* Product Cards */
  .product-banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 280px;
    overflow: hidden;
    transition: transform 0.3s ease;
    position: relative;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
  }
  
  .product-card .wishlist-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    color: #ff6f61;
  }
  
  .product-card .product-content {
    padding: 20px;
    text-align: center;
  }
  
  .product-card .product-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-card .product-details {
    margin-top: 10px;
  }
  
  .product-card .product-details .price {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
  }
  
  .product-card .product-details .product-name {
    font-size: 1.2rem;
    color: #555;
    margin: 10px 0;
  }
  
  .product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .cart_input {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .cart_input button {
    background-color: #ff6f61;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }
  
  .cart_input button:hover {
    background-color: #e55c4a;
  }
  
  .cart_input input {
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-weight: bold;
    cursor: not-allowed;
  }
  
  /* Checkout Button */
  .checkout-section {
    text-align: center;
    margin-top: 40px;
  }
  
  .checkout-button {
    background-color: #28a745;
    color: white;
    padding: 15px 30px;
    font-size: 1.5rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #218838;
  }
  
  /* Empty Cart */
  .empty_wishlist {
    text-align: center;
    padding: 40px;
  }
  
  .empty_wishlist img {
    width: 150px;
  }
  
  .empty_wishlist h1 {
    font-size: 2rem;
    color: #888;
    margin-top: 20px;
  }
  
  /* Invoice Section */
  .invoice-section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-top: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .invoice-section h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .invoice-section p {
    font-size: 1.1rem;
    color: #666;
    margin: 10px 0;
  }
  
  .invoice-section ul {
    list-style: none;
    margin: 20px 0;
    padding-left: 0;
  }
  
  .invoice-section ul li {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .invoice-section .payment-status {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    color: #28a745;
  }
  
  .payment-status p {
    color: #e55c4a; /* Default to error color for pending */
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .product-banner {
      flex-direction: column;
      align-items: center;
    }
  
    .product-card {
      width: 100%;
      max-width: 320px;
    }
  
    .invoice-section {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .banner_section {
      padding: 30px 10px;
    }
  
    .checkout-button {
      font-size: 1.2rem;
      padding: 12px 25px;
    }
  }
  