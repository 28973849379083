.service_card {
    position: relative;
    isolation: isolate;
    background-color: #fff;
    box-shadow: 0px -2px 12px #00000035;
    padding: 30px 20px;
    border-bottom: 5px solid;
    transition: all 0.5s ease-in-out;

    &:nth-child(1) {
        border-color: #fd6b65;
    }

    &:nth-child(2) {
        border-color: #97da7b;
    }

    &:nth-child(3) {
        border-color: #3179bd;
    }

    &:hover {
        transform: translateY(-10px);
    }

    img {
        width: 60px;
        height: 60px;

        &.to_bottom {
            position: absolute;
            right: 8px;
            bottom: 8px;
            width: 40%;
            height: auto;
            z-index: -1;
            opacity: 0.35;
            pointer-events: none;

        }
    }

    .title {
        color: #313131;
        font-size: 22px;
        text-transform: capitalize;
    }

    .subtitle {
        font-size: 16px;
        line-height: 26px;
        margin-top: 5px;
    }
}