.auth_form {
    background-color: #fff;
    max-width: 400px;
    margin-inline: auto;
    margin-top: -10rem;
    padding: 20px;
    box-shadow: 0px 4px 8px #00000040;
    margin-bottom: 4rem;

    h2 {
        margin-bottom: 1rem;
    }

    .form_group {
        margin-bottom: 1rem;
    }

    p {
        text-align: center;
        margin-bottom: 1rem;
    }

    .input_box {
        padding: 12px 16px;
        width: 100%;
        border-radius: 5px;
        border: 2px solid #b2b2b2;

        &:focus {
            border-color: #313131;
        }
    }

    label {
        display: block;
        color: #3e3e3e;
        font-size: 15px;
    }

    button.btn {
        padding: 12px;
        width: 100%;
        background: #FF5722;
        color: #fff;
        font-size: 18px;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
    }
}

@media screen and (max-width:500px) {
    .auth_form {
        margin-inline: 25px;
    }
}