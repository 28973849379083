.product_banner {
    margin-top: -9rem;
    margin-inline: 40px;
}

.product-card {
    padding: 14px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 0px 12px #00000040;

    .wishlist-icon {
        position: absolute;
        top: 8px;
        // left: 8px;
        padding: 7px 14px;
        background: #fff;
        border-radius: 10%;
        cursor: pointer;
        color: #878787;
        font-size: 24px;
        border: 1px solid #eee;
        box-shadow: 0px 3px 6px #00000040;
        &.delete{
            background: #f13527;
            left: auto;
            right: 8px;
            svg{
                fill: #fff;
            }
        }
        &.active {
            svg {
                fill: red;
            }
        }

        &>* {
            vertical-align: middle;
        }
    }

    .product-content {
        .product-image {
            width: 100%;
            max-width: 245px;
            display: block;
            height: 220px;
            object-fit: contain;
            margin-bottom: 1rem;
        }

        .product-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .add-to-cart-btn {
            display: block;
            padding: 10px;
            color: #000;
            font-weight: 600;
            width: 100%;
            border: 2px solid #000;
            text-align: center;
            border-radius: 30px;
            margin-top: 1rem;
            transition: all 0.5s ease-in;

            &:hover {
                background-color: #313131;
                color: #fff;
            }
        }

        .star-rating {
            color: #fff;
        }

        .price {
            font-size: 24px;
        }

        .cart_input {
            display: flex;
            width: 100%;
            border: 2px solid #212121;
            margin-top: 1rem;

            button {
                background: #ddd;
                outline: none;
                border: none;
                padding: 4px 12px;
                font-size: 22px;
            }

            input {
                width: 60%;
                flex-basis: 100%;
                border: none;
                text-align: center;
                background: #fbf8f8;
            }
        }

    }
}