.empty_wishlist {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    margin-inline: auto;
    padding: 40px;
    box-shadow: 0 4px 8px #00000080;
    margin-bottom: 4rem;
    border-radius: 5px;
    text-align: center;

    img {
        width: 100%;
    }

    h1 {
        font-size: 22px;
        margin-top: 1rem;
    }
}