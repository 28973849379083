.grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
    margin-inline: 5rem;
}

.testimonial {
    position: relative;
    isolation: isolate;
    background-color: #eee;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px #00000040;
    transition: all 1s ease-in-out;

    &:hover {
        color: #fff;
        transform: scale(1.025);

        .icon {
            color: #fff;
        }

        &::after {

            clip-path: circle(500px at 100% 100%);
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: circle(50px at 100% 100%);
        background-color: #FF5722;
        transition: all 1s linear;
        z-index: -2;
    }

    .icon {
        position: absolute;
        left: 8px;
        top: 8px;
        font-size: 6rem;
        color: #000;
        opacity: 0.2;
        z-index: -1;
        transition: all 0.5s ease-in-out;
    }

    .testimonial_bottom {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        em {
            display: block;
        }

        .star-rating {
            color: goldenrod
        }
    }
}

@media screen and (max-width:1025px) {
    .grid {
        margin-inline: 0px;
    }
}