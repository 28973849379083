.feature_section {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    flex-wrap: wrap;

    .feature-card {
        position: relative;
        isolation: isolate;
        padding: 20px;
        flex: 1 1 250px;
        background: #fff;
        border-bottom: 5px solid;
        box-shadow: -2px 2px 4px #00000040;
        transition: all 0.5s linear;

        // &:not(:hover) {
        //     rotate: 5deg;

        // }

        &:hover {
            transform: translateY(-20px);
            scale: 1.1;
        }


        &:nth-child(3) {
            border-bottom: 5px solid #97da7b;

            h3 {
                color: #97da7b;
            }
        }

        &:nth-child(2) {
            border-bottom: 5px solid #3179bd;

            h3 {
                color: #3179bd;
            }
        }

        &:nth-child(1) {
            border-bottom: 5px solid #fd6b65;

            h3 {
                color: #fd6b65;
            }
        }

        &:nth-child(4) {
            border-bottom: 5px solid #9a67f8;

            h3 {
                color: #9a67f8;
            }
        }

        img {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 100px;
            opacity: 0.5;
            z-index: -1;
        }
    }
}